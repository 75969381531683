<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-domain"
            :title="baseMaterialCardTitle"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0"
                     @click="$router.push({name: 'Accounts', params: {envCode: envSelected.value} })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="groups && groups.length > 0" no-gutters>
            <v-col cols="12" md="12">

              <template v-if="!account">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <v-tabs v-else right>

                <v-tab href="#profile">
                  <v-icon left>mdi-domain</v-icon>
                  General
                </v-tab>
                <v-tab href="#security">
                  <v-icon left>mdi-lock</v-icon>
                  Security
                </v-tab>
                <v-tab href="#multitenancy">
                  <v-icon left>mdi-apps</v-icon>
                  Multi Tenancy
                </v-tab>

                <v-tab-item class="mt-5" id="profile">
                  <v-card class="mt-0">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                              label="Organization Name *"
                              v-model="account.name"
                              prepend-icon="mdi-domain"
                              :rules="[()=> !!account.name || 'This field is required!']"
                              required
                              counter
                          />
                        </v-col>

                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item class="mt-5" id="security">
                  <user-account-security
                      :groups="groups"
                      :strategies="strategies"
                      :context="account"
                      :env-selected="envSelected"
                      :fieldAccess="fieldAccess"
                      @updateThrottlingStrategies="updateThrottlingStrategies"
                  ></user-account-security>
                </v-tab-item>

                <v-tab-item class="mt-5" id="multitenancy">
                  <user-account-multitenancy
                      v-if="renderTenants"
                      @updateTenants="updateTenants"
                      :section="'account'"
                      :context="account"
                      :env-selected="envSelected"
                      :tenants="tenants"
                      :fieldAccess="fieldAccess"
                  ></user-account-multitenancy>
                </v-tab-item>

              </v-tabs>
            </v-col>
          </v-row>
          <v-row v-if="groups && groups.length > 0" no-gutters>
            <v-col cols="12" md="12" class="text-right">
              <v-btn
                  v-if="canAccess({route: '/organization/accounts/:id', method: 'patch'})"
                  color="success" class="text-right" @click="updateAccount">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn color="error" class="ml-2 text-right" @click="reset">
                <v-icon class="mr-1">mdi-undo</v-icon>
                Reset
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else class="mt-5">
            <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
              <h2>Oops!</h2>
              <p>
                It appears you have no Groups Created.<br/>
                Therefore you cannot create any Organization Accounts.
              </p>
              <p>
                Head to the <b>Groups</b> Module from the top right User Menu.<br/>
                Create some groups, then come back to this section and you will be able to create organization accounts.
              </p>
              <v-btn color="secondary" class="mx-auto float-left" small elevation="3" @click="openDocumentation">
                <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                Read the Docks
              </v-btn>
              <v-btn color="primary" class="mx-auto float-right" small elevation="3" @click="goToGroups">
                <v-icon small color="white" class="mr-1">mdi-account-group</v-icon>
                open Groups
              </v-btn>
            </v-alert>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import usersMixins from "./components/mixins";
import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import UserAccountSecurity from "@/views/internal/pages/users/components/security";
import UserAccountMultitenancy from "@/views/internal/pages/users/components/multitenancy";

export default {
  name: 'Account',

  mixins: [globalMixins, usersMixins, fieldsMixins],

  data: () => ({
    fieldAccess: {},
    loading: false,
    search: '',
    account: null,
    groups: [],
    strategies: [],
    tenants: [],
    renderTenants: false
  }),

  components: {
    UserAccountMultitenancy,
    UserAccountSecurity,
  },

  props: {
    envSelected: {
      type: Object
    },
    id: {
      type: String
    }
  },

  async created() {
    this.initialize();
  },

  computed: {
    baseMaterialCardTitle() {
      let label = 'Creating new';
      if (this.id) {
        label = "Updating";
      }
      if (this.account) {
        return `${label} Organization ${this.account.name ? `'${this.account.name}'` : ''}`;
      } else {
        return '';
      }
    }
  },

  watch: {
    async 'search'(val) {
      if (val && val.length >= 3) {
        this.search = val;
      }
    }
  },

  methods: {

    goToGroups() {
      this.$router.push({name: 'Groups', params: {envSelected: this.envSelected}})
    },

    openDocumentation() {
      window.open(this.$helpLinks.accounts);
    },

    reset() {
      if (confirm("Are you sure you want to reset the page? (All your changes will be lost)")) {
        this.initialize();
      }
    },

    async initialize() {
      this.fieldAccess = this.accountUpdateAccess();

      this.account = null;
      const groups = await this.getSendData({
        'url': '/consoleapi/organization/groups',
        'method': 'get'
      });
      const tenants = await this.getSendData({
        'url': `/consoleapi/environments/${this.envSelected.value}/default/tenants`,
        'method': 'get',
        params: {
          noEnv: true
        }
      });

      this.groups = [];
      this.strategies = [];
      this.groups = groups.items;
      this.tenantsAll = tenants.items;
      // this.tenants = this._lodash.cloneDeep(this.tenantsAll);

      if (this.id) {
        const account = await this.getSendData({
          'url': `/consoleapi/organization/accounts/${this.id}`,
          'method': 'get'
        });

        this.account = account.item;

        //fix the throttling strategies
        if (!this.account.security) {
          this.account.security = {};
        }

        if (this.account.security.throttling) {
          let strategies = [];
          for (let i in this.account.security.throttling) {
            if (!['publicAPIStrategy', 'privateAPIStrategy'].includes(i)) {
              let strategytemp = this.account.security.throttling[i];
              strategytemp.name = i;
              strategies.push(strategytemp);
              delete this.account.security.throttling[i];
            }
          }
          this.account.security.throttling.strategies = strategies;
          //map the security
          this.strategies = this._lodash.cloneDeep(strategies);
        } else {
          this.account.security.throttling = {};
        }

        //map the tenants
        this.mapTenants();
      } else {
        this.account = {
          name: '',
          groups: [],
          tenants: [],
          security: {
            throttling: {}
          }
        }
      }

      this.$forceUpdate();
    },

    mapTenants() {
      //map the tenants
      this.tenants = this._lodash.cloneDeep(this.tenantsAll);
      for (let i = this.tenants.length - 1; i >= 0; i--) {
        let found = false;
        this.account.tenants.forEach((userTenant) => {
          if (userTenant.code === this.tenants[i].code && userTenant.id === this.tenants[i].id) {
            userTenant.name = this.tenants[i].name;
            userTenant.env = this.tenants[i].env;
            userTenant.description = this.tenants[i].description;
            found = true;
          }
        });
        if (found) {
          this.tenants.splice(i, 1);
        }
      }

      for (let i = this.account.tenants.length - 1; i >= 0; i--) {
        if (!this.account.tenants[i].env && !this.account.tenants[i].name) {
          this.account.tenants.splice(i, 1);
        }
      }
      this.renderTenants = true;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    updateThrottlingStrategies(newList) {
      this.account.security.throttling.strategies = newList;
    },

    updateTenants(item, mode) {
      const self = this;
      let apiOptions = {
        url: `/consoleapi/organization/accounts/${this.id}/tenants/${mode}`,
        method: "patch",
        params: {
          tenants: [
            {
              id: item.id,
              code: item.code
            }
          ]
        }
      };

      self.getSendData(apiOptions).then(() => {
        self.renderTenants = false;
        this.getSendData({
          'url': `/consoleapi/organization/accounts/${this.id}`,
          'method': 'get'
        }).then((account) => {
          if (account.item) {
            self.account.tenants = account.item.tenants;
          }
          self.$nextTick(() => {
            self.mapTenants();
          });
        });
      });
    },

    updateAccount() {
      const self = this;

      let newAccount = this._lodash.cloneDeep(this.account);

      if (!this.validateAccountRecord(newAccount)) {
        return false;
      }

      newAccount = this.cleanUpAccount(newAccount);

      this.filterFields(newAccount, this.fieldsAcl.account.update);
      delete newAccount.tenants;
      delete newAccount.config;

      let apiOptions = {
        url: `/consoleapi/organization/accounts/${this.id}`,
        method: "patch",
        params: newAccount
      };

      self.getSendData(apiOptions)
          .then(() => {
            self.pushMessage({
              type: 'success',
              title: `Organization updated`,
              text: `Organization Information, Security and Multitenancy have been updated!`
            });
            self.scrollToTop();
            setTimeout(() => {
              this.initialize();
            }, 2000);

          });
    },

  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
